import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        if (tokenString != undefined && tokenString != "undefined") {
            const userToken = JSON.parse(tokenString);
            return userToken;
        }
        return null;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken: { access_token: string }) => {
        localStorage.setItem("token", JSON.stringify(userToken.access_token));
        setToken(userToken.access_token);
    };
    const clearToken = () => {
        localStorage.clear();
    };
    return {
        setToken: saveToken,
        token,
        clearToken,
        getToken,
    };
}
