import { ILoggedUser, deleteLoggedUser } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button, Space } from 'antd';
import './ideas.css'

export default function Ideas() {
    const loggedUser: ILoggedUser = useSelector(
        (state: any) => state.loggedUser.loggedUser
    );

    const navigator = useNavigate()
    return (
        <div id="main-container">
            <div id="left-container"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                }}>

                
                <div style={{ flex: 2.5 }} />
                <div style={{ flex: 1 }}>
                    <h1 style={{
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: 'inter-bold',
                        fontSize: '56px'
                    }}>
                        Tus ideas
                    </h1>
                </div>

                <div style={{ flex: 2.5 }} />
                <div style={{
                    flex: '1'
                }}>
                    <p id='title-muguerza'>CHRISTUS MUGUERZA</p>
                </div>
            </div>
            <div id="right-container"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%'
                }}>

                <div
                    style={{
                        marginBlock: "25px",
                        marginInline: "30px",
                    }}>
                    <Button onClick={() => navigator("/")} icon={<LeftOutlined />}>Regresar</Button>
                </div>
                <img style={{ alignItems: 'center', alignSelf: 'center', marginTop: '25%', scale: '130%' }} src={require('../assets/ideaslogo_blanco.png')} width={100}></img>
                <div style={{ flex: 3 }} />
                <div style={{
                    flex: 1,
                    width: '16vw',
                    alignSelf: 'center'
                }}>
                    <Space direction='vertical' size="middle" style={{
                        width: '100%'
                    }}>
                        <Button style={{
                            height: '50px',
                            fontSize: '18px',
                            fontFamily: 'quicksand-600',
                        }} onClick={() => navigator('/share_idea')} block>Comparte tu idea</Button>
                        <div style={{ height: '20px' }} />
                        { loggedUser.isAdmin == 1 && (
                        <Button style={{
                            height: '50px',
                            fontSize: '18px',
                            fontFamily: 'quicksand-600',
                        }} onClick={() => navigator('/kanban')} block>Kanban</Button> )}
                    </Space>
                </div>
                <div style={{
                    display: 'flex',
                    flex: '1',
                    alignItems: 'center'
                }}>
                </div>

            </div>
        </div>
    )
}