import { Button, Input, Select, Tag, notification } from "antd";
import hospitalsServices from "../services/hospitalsServices";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import usersServices from "../services/usersServices";
import ideasServices from "../services/ideasServices";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./share_idea.css";

export default function ShareIdea() {
    const navigator = useNavigate();
    const handleSelectionAuthors = (value: any) => {
        setSelectedAuthors(value);
    };
    const handleSelectionHospitalArea = (value: any) => {
        setSelectedHospitalArea(value);
    };
    const [userOptions, setUserOptions] = useState<any>([]);
    const [hospitalAreaOptions, setHospitalAreaOptions] = useState<any>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<any>([]);
    const [selectedHospitalArea, setSelectedHospitalArea] = useState<any>([]);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [scope, setScope] = useState<string>("");
    const [resources, setResources] = useState<string>("");

    const getAllUsers = () => {
        usersServices.getAllAuthors().then((users) => {
            let aux = [];
            for (let user of users) {
                aux.push({
                    label: `${user["first_name"]} ${user["last_name"]}`,
                    value: user["id"],
                });
            }
            setUserOptions(aux);
        });
    };

    const getAllHospitalAreas = () => {
        hospitalsServices.getAllHospitalAreas().then((hospitalAreas) => {
            let aux = [];
            for (let hospitalArea of hospitalAreas) {
                aux.push({
                    label: hospitalArea["hospital_name"],
                    value: hospitalArea["id"],
                });
            }
            setHospitalAreaOptions(aux);
        });
    };

    useEffect(() => {
        getAllUsers();
        getAllHospitalAreas();
    }, []);

    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (
            event: React.MouseEvent<HTMLSpanElement>
        ) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color="purple"
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    const createIdea = () => {
        let body = {
            title: title,
            description: description,
            hospital_area_id: selectedHospitalArea,
            scope: scope,
            resources: resources,
            authors: selectedAuthors,
        };
        if(body.title && body.description && body.hospital_area_id && body.scope && body.resources && body.authors && body.authors.length > 0) {
            ideasServices
            .createIdea(body)
            .then(() => {
                notification.success({
                    message: "¡Tu idea cambiará vidas!",
                });
                clearForm();
            })
            .catch(() => {
                notification.error({
                    message: "Ups!! algo salió mal.",
                });
            });
        }
        else {
            notification.error({
                message: "Por favor llena todos los campos.",
            })
        }

    };

    const clearForm = () => {
        setTitle("");
        setSelectedAuthors(undefined);
        setSelectedHospitalArea(undefined);
        setDescription("");
        setResources("");
        setScope("");
    };

    return (
        <div
            style={{
                height: "100vh",
                backgroundColor: "#EDEDED",
            }}
        >
            <img
                src={require("../assets/share_idea_background.png")}
                style={{
                    width: "85vh",
                    position: "absolute",
                    zIndex: 1,
                }}
            ></img>
            <div
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className="inner">
                    <div
                        style={{
                            marginTop: "2%",
                            marginLeft: "5%",
                            marginBottom: "3%",
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <h1 id="title-share-idea">
                            Comparte tu idea con nosotros
                        </h1>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <img
                                src={require("../assets/ideas_logo_blanco.png")}
                                style={{
                                    width: "25%",
                                    marginRight: "5%",
                                }}
                            ></img>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div
                        style={{
                            marginTop: "5%",
                            marginRight: "5%",
                            marginBottom: "3.5vh",
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <label className="label-campo">
                                    Nombre de la idea
                                </label>
                                <Input
                                    value={title}
                                    onChange={(e) =>
                                        setTitle(e.currentTarget.value)
                                    }
                                    className="input-style"
                                ></Input>
                            </div>
                            <div>
                                <label className="label-campo">Autores</label>
                                <Select
                                    style={{
                                        background: "transparent",
                                        marginBottom: "5%",
                                        width: "100%",
                                    }}
                                    mode="multiple"
                                    placeholder="Busca los autores"
                                    tagRender={tagRender}
                                    filterOption={filterOption}
                                    options={userOptions}
                                    onChange={handleSelectionAuthors}
                                    value={selectedAuthors}
                                    loading={userOptions.length == 0}
                                ></Select>
                            </div>
                            <div>
                                <label className="label-campo">
                                    Descripción
                                </label>
                                <Input
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.currentTarget.value)
                                    }
                                    className="input-style"
                                ></Input>
                            </div>
                            <div>
                                <label className="label-campo">Alcance</label>
                                <Input
                                    value={scope}
                                    onChange={(e) =>
                                        setScope(e.currentTarget.value)
                                    }
                                    className="input-style"
                                ></Input>
                            </div>
                            <div>
                                <label className="label-campo">
                                    Recursos necesarios
                                </label>
                                <Input
                                    value={resources}
                                    onChange={(e) =>
                                        setResources(e.currentTarget.value)
                                    }
                                    className="input-style"
                                ></Input>
                            </div>
                            <div>
                                <label className="label-campo">Unidad</label>
                                <Select
                                    className="select-style"
                                    placeholder="Busca la unidad"
                                    tagRender={tagRender}
                                    options={hospitalAreaOptions}
                                    onChange={handleSelectionHospitalArea}
                                    value={selectedHospitalArea}
                                    loading={hospitalAreaOptions.length == 0}
                                    allowClear
                                ></Select>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginRight: "0.8vw",
                                }}
                            >
                                <Button
                                    id="turn-back-button"
                                    onClick={() => navigator(-1)}
                                >
                                    Volver atrás
                                </Button>
                            </div>

                            <Button
                                id="create-idea-button"
                                onClick={createIdea}
                            >
                                Compartir
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
