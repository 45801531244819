const getUriEnviroment = (): string => {
    return process.env.REACT_APP_API_URL ?? "";
};

const getClientIdEnviroment = (): string => {
    return process.env.REACT_APP_CLIENT_ID ?? "";
};

const getAuthorityEnviroment = (): string => {
    return process.env.REACT_APP_AUTHORITY ?? "";
};

export const URI = getUriEnviroment();
export const URI_API = URI + "/api";
export const CLIENT_ID = getClientIdEnviroment();
export const AUTHORITY = getAuthorityEnviroment();
