const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString!);

    return userToken;
};

const getHeaders = () => {
    const token = getToken();
    const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` ?? "",
    };
    return header;
};
export default { getHeaders };
