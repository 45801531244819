import { Button, Divider, Space, Image } from "antd";
import TopLeftIlustration from "../assets/login_view/ilustracion_topleft.svg";
import IllustrationBottomleft from "../assets/login_view/ilustracion_bottomleft.svg";
import BackgroundLeft from "../assets/login_view/background_left.svg";
import BackgroundRight from "../assets/login_view/background_right.svg";
import GrainBottomLefft from "../assets/login_view/grain_bottomleft.png";
import GrainTopRoght from "../assets/login_view/grain_topright.png";
import IllusrationRight from "../assets/login_view/illustracion_right.svg";
import IdeasLogo from "../assets/ideas_logo_traditional.png";
import usersServices from "../services/usersServices";
import { useMsal } from "@azure/msal-react";
import useToken from "../utils/useToken";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ILoggedUser, setLoggedUser } from "../store/actions";

export default function LoginView() {
    const dispatch: Dispatch<any> = useDispatch();

    const { setToken } = useToken();

    const { instance } = useMsal();

    const initializeSignIn = (): void => {
        instance
            .loginPopup()
            .then(async (res) => {
                let idTokenClaims = res.idTokenClaims as any;

                let res_token = await loginUser({
                    userName: idTokenClaims["preferred_username"],
                    password: "password",
                });

                setToken(res_token);
            })
            .then(() => {
                usersServices.getUserLogged().then((res) => {
                    const user: ILoggedUser = {
                        id: res.id as number,
                        initials: res.first_name[0] + res.last_name[0],
                        name: res.first_name,
                        lastName: res.last_name,
                        fullName: res.first_name + " " + res.last_name,
                        roleName: res.role_name,
                        isAdmin: res.isAdmin,
                    };

                    dispatch(setLoggedUser(user));
                });
            });
    };

    async function loginUser(credentials: {
        userName: string;
        password: string;
    }) {
        let formData = new FormData();
        formData.append("username", credentials.userName);
        formData.append("password", credentials.password);
        return usersServices.loginUser(formData);
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            {/* half screen left  */}
            <div style={{ flex: 2, height: "100vh" }}>
                <img
                    src={BackgroundLeft}
                    width={"69%"}
                    alt=""
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        bottom: 0,
                    }}
                />
                <img
                    src={TopLeftIlustration}
                    alt=""
                    width={"10%"}
                    style={{ zIndex: 2, position: "absolute", left: 0, top: 0 }}
                />
                <img
                    src={IllustrationBottomleft}
                    alt=""
                    width={"40%"}
                    style={{
                        zIndex: 2,
                        position: "absolute",
                        left: 0,
                        bottom: "-13%",
                    }}
                />
                <img
                    src={GrainBottomLefft}
                    alt=""
                    width={"25%"}
                    style={{
                        zIndex: 3,
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                    }}
                />
                {/* HEADER */}
                <div
                    style={{
                        zIndex: 4,
                        position: "relative",
                        margin: "6%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    className="space-align-block"
                >
                    <Space direction="horizontal" align="end">
                        <Image
                            src={IdeasLogo}
                            width={140}
                            preview={false}
                        ></Image>
                        <Divider
                            type="vertical"
                            style={{
                                height: "40px",
                                borderRadius: "8px",
                                border: "1px solid #671E75",
                            }}
                        ></Divider>
                        <span
                            style={{
                                fontSize: 16,
                                fontFamily: "quicksand-500",
                            }}
                        >
                            La chispa de inspiración y creatividad
                            <br></br>
                            para impulsar avances en salud.
                        </span>
                    </Space>
                </div>
                {/* LOGIN FORM */}
                <div
                    style={{
                        zIndex: 4,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        height: "50%",
                        alignItems: "center",
                    }}
                    className="space-align-block"
                >
                    <Space direction="vertical">
                        <div
                            style={{
                                fontFamily: "figtree-bold",
                                color: "#8A1B61",
                                fontSize: 32,
                                textAlign: "center",
                            }}
                        >
                            ¡Hola, te extrañamos!
                        </div>
                        <div
                            style={{
                                fontFamily: "quicksand-400",
                                fontSize: 25,
                                marginBottom: "5%",
                                textAlign: "center",
                            }}
                        >
                            Nos estuvimos preparando <br /> para tu siguiente
                            idea.
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10%",
                            }}
                        >
                            <Button
                                onClick={initializeSignIn}
                                style={{
                                    backgroundColor: "#5AAE39",
                                    color: "white",
                                    fontFamily: "quicksand-600",
                                    fontSize: 14,
                                    width: "150px",
                                    border: "1px solid #5AAE39",
                                }}
                            >
                                Entrar
                            </Button>
                        </div>
                    </Space>
                </div>
            </div>

            {/* half screen right */}
            <div style={{ flex: 1 }}>
                <img
                    src={BackgroundRight}
                    height={"100%"}
                    alt=""
                    style={{
                        zIndex: -1,
                        position: "absolute",
                        right: 0,
                        top: 0,
                        justifySelf: "start",
                    }}
                />
                <img
                    src={IllusrationRight}
                    alt=""
                    height={"100%"}
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        right: 0,
                        top: 0,
                    }}
                />
                <img
                    src={GrainTopRoght}
                    alt=""
                    height={"100%"}
                    style={{
                        zIndex: 2,
                        position: "absolute",
                    }}
                />
            </div>
        </div>
    );
}
