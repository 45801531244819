// import api from "../utils/api";
import { URI_API } from "../utils/api";
import useHeaders from "../utils/useHeaders";

const createIdea = async (body: any) => {
    return await fetch(`${URI_API}/idea/create`, {
        method: "POST",
        headers: useHeaders.getHeaders(),
        body: JSON.stringify(body),
    }).then((res) => res.json());
};

const getTopIdeas = async () => {
    return await fetch(`${URI_API}/idea/top`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((response) => response.json());
};

const getFinishedIdeas = async () => {
    return await fetch(`${URI_API}/idea/all_finished`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((response) => response.json());
};

const getUnfinishedIdeas = async () => {
    return await fetch(`${URI_API}/idea/all_unfinished`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((response) => response.json());
};

const getIdea = async (ideaId: number) => {
    return await fetch(`${URI_API}/idea/${ideaId}`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((res) => res.json());
};

const upVote = async (ideaId: number) => {
    return await fetch(`${URI_API}/idea/vote/${ideaId}`, {
        method: "PUT",
        headers: useHeaders.getHeaders(),
    }).then((res) => res.json());
};

export default {
    createIdea,
    getTopIdeas,
    getFinishedIdeas,
    getUnfinishedIdeas,
    getIdea,
    upVote,
};
