import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ideasServices from "../services/ideasServices";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

import "./success_cases.css";
import { Button, notification } from "antd";
import useToken from "../utils/useToken";
import { useNavigate } from "react-router";

export default function SuccessCases() {
    const navigator = useNavigate();
    const [ideas, setIdeas] = useState<any[]>([
        {
            authors: [
                {
                    id: 100,
                    first_name: "Direccción Experiencia del Consumidor",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Direccción Experiencia del Consumidor",
            created_by_role: "Direccción Experiencia del Consumidor",
            description:
                "La Membresía Socio CHRISTUS se creó para crear lealtad entre los pacientes hacia CHRISTUS MUGUERZA a través de una membresía gratuita que otorga descuentos en diversos servicios de los hospitales, aligerando la carga financiera del paciente, recibiendo no solo una atención de calidad, sino también una más accesible. Actualmente es una fuente de ingresos en los Hospitales, que tiene impacto en la Transformación.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/membership_christus.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Membresía Socio CHRISTUS",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Direccción Experiencia del Consumidor",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Direccción Experiencia del Consumidor",
            created_by_role: "Direccción Experiencia del Consumidor",
            description:
                "El comercio electrónico es un modelo de negocio por el cual CHRISTUS MUGUERZA puede ofrecer los servicios de los hospitales a través de una Tienda en Línea y mediante Links de Pago para facilitar y ahorrar filas para adquirir servicios. Actualmente se adquieren miles de servicios anualmente a través de la Tienda en Línea, así como el pago de servicios a través de Links de Pago en nuestras recepciones.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/ecommerce.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "eCommerce",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Direccción Experiencia del Consumidor",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Direccción Experiencia del Consumidor",
            created_by_role: "Direccción Experiencia del Consumidor",
            description:
                "El Centro de Contacto facilita las interacciones de los pacientes de CHRISTUS MUGUERZA a través de distintos canales de comunicación como llamadas telefónicas, chats en línea, redes sociales y correos electrónicos, con el objetivo de proporcionar un servicio eficiente y satisfactorio. Con esta iniciativa activa y en expansión, se tiene una mayor atención y mejor retención de los pacientes, generando impactos EBITDA de Transformación.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/contact_center.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Centro de Contacto",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Subdirección Comercial y Ventas",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Subdirección Comercial y Ventas",
            created_by_role: "Subdirección Comercial y Ventas",
            description:
                "Los Servicios Integrados son convenios realizados con empresas en la que se les ofrecen descuentos específicos en los servicios de CHRISTUS MUGUERZA a personas afiliados a las empresas ya sea por medio de contratos laborales o comerciales.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/integrated_services.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Servicios Integrados",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Tecnologías de la Información",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Tecnologías de la Información",
            created_by_role: "Tecnologías de la Información",
            description:
                "Con un alcance planeado para todas las unidades de servicio actuales en el grupo, el proyecto apoya la integridad de la información clínica, administrativa y financiera con su confiabilidad al reemplazar los sistemas HIS, EMR y RCM del sistema de salud. Esto buscará que los procesos fluyan de manera más transparente, minimizando integraciones transversalmente por toda la organización. Por otro lado, la implementación del expediente clínico electrónico tiene un gran aporte en términos de calidad en la atención clínica y sobre todo en seguridad del paciente, al reducir la probabilidad de errores humanos.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/conecta_health.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "CONECTA Salud",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Subdirección Comercial y Ventas",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Subdirección Comercial y Ventas",
            created_by_role: "Subdirección Comercial y Ventas",
            description:
                "Es la transformación de nuestras farmacias actuales con una propuesta de solución tecnológica y estrategias comerciales, de suministro,  mercadotecnia y capacitación que mejoren la experiencia del paciente. Se busca que CHRISTUS MUGUERZA ofrezca una opción de venta de medicamentos a precios competitivos y con la excelente calidad de servicios que nos distingue.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/public_pharmacy.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Farmacia Venta al Público",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Innovación en Salud",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Innovación en Salud",
            created_by_role: "Innovación en Salud",
            description:
                "Implementación de un sistema para la gestión de los bancos de sangre y servicios de transfusión en todas las unidades, que permita aumentar la seguridad, mejorar la gestión de stock, así como la generación de reportes. El procesamiento de sangre y sus componentes es crítico para las operaciones hospitalarias, y la gestión del banco de sangre es auditada continuamente. Adicionalmente las capacidades del banco de sangre se han incrementado en años recientes por lo que es indispensable contar con un software robusto que permita la operación ágil y segura de forma centralizada.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/blood_bank.jpg"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Banco de Sangre",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Innovación en Salud",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Innovación en Salud",
            created_by_role: "Innovación en Salud",
            description:
                "A través de nuevas tecnologías que usan Inteligencia Artificial (IA) se integran a los estudios de imagenología, con el cual se pueden obtener resultados más precisos detectando puntos focales para los médicos en las imágenes obtenidas. Este tipo de iniciativas impactan en la innovación en la atención al paciente, así como la excelencia clínica en los hospitales de CHRISTUS MUGUERZA.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/ai.png"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "IA en Imagenología",
            visibility: "public",
        },
        {
            authors: [
                {
                    id: 100,
                    first_name: "Innovación en Salud",
                    last_name: "",
                },
            ],
            category_id: 0,
            created_at: "2023-10-06T05:48:46.200000+00:00",
            created_by_id: 100,
            created_by_name: "Innovación en Salud",
            created_by_role: "Innovación en Salud",
            description:
                "El proyecto busca analizar el historial de atención del dolor, identificando características clave para predecir episodios de dolor. Se enfoca en entrenar un modelo de aprendizaje automático y desplegar un sistema de predicción en vivo para casos de dolor postoperatorio. Con antecedentes que revelan que más del 80% de los pacientes postcirugía experimentan dolor, y que el control inadecuado del dolor afecta negativamente la calidad de vida, el objetivo es mejorar la gestión del dolor postoperatorio para optimizar la recuperación y reducir el riesgo de complicaciones.",
            hospital_area_id: 1,
            hospital_area_name: "",
            idea_id: 100,
            image_url: require("../assets/ideas_images/pain_prediction_model.jpg"),
            resources: "",
            scope: "",
            status_id: 3,
            title: "Modelo Predictivo del Dolor",
            visibility: "public",
        },
    ]);
    /*useEffect(() => {
        fetch(
            "https://re25back.orangestone-b2099b25.southcentralus.azurecontainerapps.io/api/idea/all",
            {
                headers: {
                    Authorization: `Bearer ${tokenController.getToken()}`,
                },
            }
        )
            .then((res) => res.json())
            .then((ideas_response) => setIdeas(ideas_response));
    }, []);*/

    const upvote = (ideaId: number) => {
        ideasServices.upVote(ideaId).then(() =>
            notification.success({
                message: "¡Muchas gracias por tu voto!",
            })
        );
    };

    return (
        <Swiper
            modules={[Mousewheel]}
            direction={"vertical"}
            slidesPerView={1}
            spaceBetween={30}
            mousewheel={true}
            className="mySwiper"
        >
            {ideas.map((idea) => (
                <SwiperSlide key={idea.idea_id}>
                    <div
                        style={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <img
                            src={require("../assets/left_background_idea.jpg")}
                            style={{
                                position: "absolute",
                                height: "100%",
                                opacity: 0.3,
                                zIndex: 1,
                            }}
                        ></img>
                        <div
                            style={{
                                flex: 4,
                                marginBlock: "60px",
                                marginInline: "80px",
                                display: "flex",
                                flexDirection: "column",
                                zIndex: 2,
                            }}
                        >
                            <Button
                                style={{
                                    maxWidth: "100px",
                                }}
                                onClick={() => navigator(-1)}
                            >
                                Regresar
                            </Button>
                            <div id="idea-container">
                                <h1 id="idea-title">{idea["title"]}</h1>
                                <div id="idea-information">
                                    {idea.description}
                                </div>
                                <div>
                                    <h3 id="idea-subtitle">Autor(es):</h3>
                                    {idea.authors.map((author: any) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <div className="idea-author-name">
                                                {author["first_name"]}{" "}
                                                {author["last_name"]}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div id="idea-footer">
                                <img
                                    src={require("../assets/ideas_logo_traditional.png")}
                                    height={100}
                                ></img>
                                <div id="like-container">
                                    <div id="text">
                                        <h5>¿Te agrada esta idea?</h5>
                                        <span>¡Dale "Me gusta"!</span>
                                    </div>
                                    <div
                                        onClick={() => upvote(idea["idea_id"])}
                                        id="like-button"
                                    >
                                        <img
                                            src={require("../assets/favorite_idea_icon.png")}
                                            height={100}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 3,
                                zIndex: 2,
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={idea.image_url}
                                /*style={{
                                height: "100%",
                            }}*/
                            ></img>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
