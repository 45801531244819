import { BrowserRouter, Route, Routes } from "react-router-dom";
import IdeaInformation from "./views/idea_information";
import SuccessCases from "./views/success_cases";
import ShareIdea from "./views/share_idea";
import LoginView from "./views/login_view";
import MeetIdeas from "./views/meet_ideas";
import HomeView from "./views/home_view";
import Kanban from "./views/kanban";
import Ideas from "./views/ideas";
import News from "./views/news";

// MSAL imports for login
import { IPublicClientApplication } from "@azure/msal-browser";
import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
} from "@azure/msal-react";

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    return (
        <MsalProvider instance={pca}>
            <>
                <UnauthenticatedTemplate>
                    <Routes>
                        <Route
                            path="/"
                            element={<LoginView></LoginView>}
                        ></Route>
                    </Routes>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/" Component={HomeView}></Route>
                        <Route path="/share_idea" Component={ShareIdea}></Route>
                        <Route
                            path="/success_cases"
                            Component={SuccessCases}
                        ></Route>
                        <Route path="/kanban" Component={Kanban}></Route>
                        <Route path="/your_ideas" Component={Ideas}></Route>
                        <Route path="/news" Component={News}></Route>
                        <Route path="/meet_ideas" Component={MeetIdeas}></Route>
                        <Route
                            path="/idea_information"
                            Component={IdeaInformation}
                        ></Route>
                    </Routes>
                </AuthenticatedTemplate>
            </>
        </MsalProvider>
    );
}

export default App;
