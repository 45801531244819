import { ILoggedUser, deleteLoggedUser } from "../store/actions";
import { DndContext, rectIntersection } from "@dnd-kit/core";
import { useDispatch, useSelector } from "react-redux";
import KanbanLane from "../widgets/kanbanLane";
import { useEffect, useState } from "react";
import useToken from "../utils/useToken";
import "./kanban.css";
import { Button } from "antd";
import { redirect, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { URI_API } from "../utils/api";

export default function KanbanBoard() {
    const loggedUser: ILoggedUser = useSelector(
        (state: any) => state.loggedUser.loggedUser
    );

    const navigator = useNavigate();
    const tokenController = useToken();
    useEffect(() => {
        fetch(`${URI_API}/idea/all`, {
            headers: {
                Authorization: `Bearer ${tokenController.getToken()}`,
            },
        })
            .then((res) => res.json())
            .then((ideas) => {
                let todoAux = [];
                let doneAux = [];
                let inProgressAux = [];
                let uAux = [];
                let impAux = [];
                let backAux = [];
                for (let idea of ideas) {
                    switch (idea["status_id"]) {
                        case 1:
                            todoAux.push(idea);
                            break;
                        case 2:
                            inProgressAux.push(idea);
                            break;
                        case 3:
                            doneAux.push(idea);
                            break;
                        case 4:
                            uAux.push(idea);
                            break;
                        case 5:
                            impAux.push(idea);
                            break;
                        case 6:
                            backAux.push(idea);
                            break;
                    }
                }
                setTodoItems(todoAux);
                setInProgressItems(inProgressAux);
                setDoneItems(doneAux);
                setuItems(uAux);
                setInImplementationItems(impAux);
                setBacklogItems(backAux);
            });
    }, []);
    const [todoItems, setTodoItems] = useState<Array<any>>([]);
    const [doneItems, setDoneItems] = useState<Array<any>>([]);
    const [inProgressItems, setInProgressItems] = useState<Array<any>>([]);
    const [uItems, setuItems] = useState<Array<any>>([]);
    const [inImplementationItems, setInImplementationItems] = useState<
        Array<any>
    >([]);
    const [backlogItems, setBacklogItems] = useState<Array<any>>([]);

    const updateStatus = (ideaId: number, statusId: number) => {
        fetch(`${URI_API}/idea/update_status/${ideaId}/${statusId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${tokenController.getToken()}`,
            },
        })
            .then((res) => res.json())
            .then(() => console.log("succefully updated"));
    };
    
    if(loggedUser.isAdmin == 1) 
    return (
        <div id="backbackground" style={{ overflowY: "scroll" }}>
            <div
                style={{
                    marginBlock: "25px",
                    marginInline: "30px",
                }}
            >
                <Button onClick={() => navigator(-1)} icon={<LeftOutlined />}>
                    Regresar
                </Button>
            </div>
            <DndContext
                collisionDetection={rectIntersection}
                onDragEnd={(e) => {
                    console.log(e.active.data.current);
                    const container = e.over?.id;
                    const idea_id = e.active.data.current?.idea_id;
                    const title = e.active.data.current?.title ?? "";
                    const index = e.active.data.current?.index ?? 0;
                    const parent = e.active.data.current?.parent ?? "IDEA";
                    const created_at = e.active.data.current?.created_at;
                    const authors = e.active.data.current?.authors;

                    if (container === parent) return;

                    if (container === "IDEA") {
                        updateStatus(idea_id, 1);
                        setTodoItems([
                            ...todoItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    } else if (container === "EN REVISIÓN") {
                        updateStatus(idea_id, 3);
                        setDoneItems([
                            ...doneItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    } else if (container === "PLANEACIÓN") {
                        updateStatus(idea_id, 2);
                        setInProgressItems([
                            ...inProgressItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    } else if (container === "IMPLEMENTACIÓN") {
                        updateStatus(idea_id, 5);
                        setInImplementationItems([
                            ...inImplementationItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    } else if (container === "FINALIZADO") {
                        updateStatus(idea_id, 4);
                        setuItems([
                            ...uItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    } else if (container === "BACKLOG") {
                        updateStatus(idea_id, 6);
                        setBacklogItems([
                            ...backlogItems,
                            { title, created_at, authors, idea_id },
                        ]);
                    }
                    if (parent === "IDEA") {
                        setTodoItems([
                            ...todoItems.slice(0, index),
                            ...todoItems.slice(index + 1),
                        ]);
                    } else if (parent === "EN REVISIÓN") {
                        setDoneItems([
                            ...doneItems.slice(0, index),
                            ...doneItems.slice(index + 1),
                        ]);
                    } else if (parent === "FINALIZADO") {
                        setuItems([
                            ...uItems.slice(0, index),
                            ...uItems.slice(index + 1),
                        ]);
                    } else if (parent === "PLANEACIÓN") {
                        setInProgressItems([
                            ...inProgressItems.slice(0, index),
                            ...inProgressItems.slice(index + 1),
                        ]);
                    } else if (parent === "IMPLEMENTACIÓN") {
                        setInImplementationItems([
                            ...inImplementationItems.slice(0, index),
                            ...inImplementationItems.slice(index + 1),
                        ]);
                    } else if (parent === "BACKLOG") {
                        setBacklogItems([
                            ...backlogItems.slice(0, index),
                            ...backlogItems.slice(index + 1),
                        ]);
                    }
                }}
            >
                <div
                    style={{
                        display: "flex",
                        margin: "3% 8% 8% 8%",
                    }}
                >
                    <KanbanLane
                        headerColor="#ADDC91"
                        title="IDEA"
                        items={todoItems}
                    />
                    <KanbanLane
                        headerColor="#97D700"
                        title="EN REVISIÓN"
                        items={doneItems}
                    />
                    <KanbanLane
                        headerColor="#468F2A"
                        title="PLANEACIÓN"
                        items={inProgressItems}
                    />
                    <KanbanLane
                        headerColor="#8A1B61"
                        title="IMPLEMENTACIÓN"
                        items={inImplementationItems}
                    />
                    <KanbanLane
                        headerColor="#671E75"
                        title="FINALIZADO"
                        items={uItems}
                    />
                    <KanbanLane
                        headerColor="#BEBEBE"
                        title="BACKLOG"
                        items={backlogItems}
                    />
                </div>
            </DndContext>
        </div>
    ); else {
        navigator('/');
        return null;
    }
}
