export const SET_LOGGED_USER = "SET_LOGGED_USER";
export const DELETE_LOGGED_USER = "DELETE_LOGGED_USER";

export interface ILoggedUser {
    id: number;
    initials: string;
    name: string;
    lastName: string;
    fullName: string;
    roleName: string;
    isAdmin: number;
}

export const setLoggedUser = (user: ILoggedUser) => ({
    type: SET_LOGGED_USER,
    payload: user,
});

export const deleteLoggedUser = (userId: number) => ({
    type: DELETE_LOGGED_USER,
    payload: userId,
});
