import backgroundImage from "../assets/meet_ideas_background.jpg";
import ideasServices from "../services/ideasServices";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "antd";
import "./meet_ideas.css";
import dayjs from "dayjs";

export default function MeetIdeas() {
    const navigator = useNavigate();
    const [finishedIdeas, setFinishedIdeas] = useState([]);
    const [unfinishedIdeas, setUnfinishedIdeas] = useState([]);

    useEffect(() => {
        ideasServices.getFinishedIdeas().then((res) => setFinishedIdeas(res));
        ideasServices
            .getUnfinishedIdeas()
            .then((res) => setUnfinishedIdeas(res));
    }, []);

    const getFormatDate = (date: Date): string => {
        return dayjs(date).format("DD/MM/YYYY");
    };

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div id="left-container-header">
                        <img
                            src={require("../assets/ideas_logo_blanco.png")}
                            alt="Ideas Logo"
                        />
                        <img
                            src={require("../assets/logo_connect_blanco.png")}
                            alt="Ideas Logo"
                        />
                    </div>

                    <div id="left-container-text">
                        <h1>Conoce otras ideas</h1>
                        <h3>
                            ¡Conoce nuevas ideas! Haz click en cada una para
                            saber más sobre su historia.
                        </h3>
                        <Button onClick={() => navigator("/share_idea")}>
                            Comparte tu idea
                        </Button>
                    </div>

                    <h2 id="title-muguerza">CHRISTUS MUGUERZA</h2>
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    background: "var(--neutral-neutral-200, #EDEDED)",
                }}
            >
                <div
                    style={{
                        marginBlock: "25px",
                        marginInline: "30px",
                    }}
                >
                    <Button
                        onClick={() => navigator(-1)}
                        icon={<LeftOutlined />}
                    >
                        Regresar
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        height: "100%",
                    }}
                >
                    {finishedIdeas.length != 0 && (
                        <div className="ideas-container">
                            <div id="finish-ideas" className="ideas-header">
                                IDEAS HECHAS REALIDAD
                            </div>
                            <div className="ideas-wrapper">
                                {finishedIdeas.map((idea) => (
                                    <div
                                        className="item-list"
                                        key={idea["id"]}
                                        onClick={() =>
                                            navigator("/idea_information", {
                                                state: {
                                                    idea_id: idea["id"],
                                                },
                                            })
                                        }
                                    >
                                        <div>
                                            <div className="item-title">
                                                {idea["title"]}
                                            </div>
                                            <div className="item-date">
                                                {getFormatDate(
                                                    idea["created_at"]
                                                )}
                                            </div>
                                        </div>
                                        <div className="item-tag tag-finish-idea"></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div
                        className="ideas-container"
                        style={{ paddingTop: "1rem" }}
                    >
                        <div id="new-ideas" className="ideas-header">
                            NUEVAS IDEAS
                        </div>
                        <div className="ideas-wrapper">
                            {unfinishedIdeas.map((idea) => (
                                <div
                                    className="item-list"
                                    key={idea["id"]}
                                    onClick={() =>
                                        navigator("/idea_information", {
                                            state: {
                                                idea_id: idea["id"],
                                            },
                                        })
                                    }
                                >
                                    <div>
                                        <div className="item-title">
                                            {idea["title"]}
                                        </div>
                                        <div className="item-date">
                                            {getFormatDate(idea["created_at"])}
                                        </div>
                                    </div>

                                    <div className="item-tag tag-new-idea"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
