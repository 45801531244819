import { Configuration, PopupRequest } from "@azure/msal-browser";
import { CLIENT_ID, AUTHORITY } from "./api";

export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: AUTHORITY,
        redirectUri: "/login",
        postLogoutRedirectUri: "/",
    },
};

export const loginRequest: PopupRequest = {
    scopes: ["User.Read"],
};
