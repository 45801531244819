import { Button, Space, Image } from "antd";
import { useNavigate } from "react-router";
import "./news.css";

import NewsBackground from "../assets/webinar_images/Webinar1.png";
import NewsFigures from "../assets/background_shapes_news.svg";
import { LeftOutlined } from "@ant-design/icons";

export default function News() {
    const navigator = useNavigate();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        zIndex: -1,
                        position: "absolute",
                        backgroundColor: "#EDEDED",
                        width: "100%",
                        height: "100%",
                    }}
                ></div>
                <img
                    src={NewsFigures}
                    alt=""
                    width={"95%"}
                    style={{ zIndex: 1, position: "absolute" }}
                />
                <div
                    style={{
                        zIndex: 2,
                        position: "relative",
                    }}
                >
                    <Space direction="vertical" style={{ margin: "10%" }}>
                        <Button
                            icon={<LeftOutlined />}
                            onClick={() => navigator(-1)}
                            style={{ marginBottom: "5%" }}
                        >
                            Regresar
                        </Button>
                        <div
                            style={{
                                maxHeight: "400px",
                                overflow: "auto",
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "quicksand-500",
                                    fontSize: 20,
                                    color: "#2F2F2F",
                                }}
                            >
                                Como parte del programa de educación de Ideas
                                CHRISTUS MUGUERZA en conjunto con Universidad
                                CHRISTUS MUGUERZA, te invitamos a participar en
                                el webinar titulado ‘Motor de ventas digitales:
                                todos somos vendedores’ tratando el tema de
                                E-Commerce y cómo impulsarlo en las unidades
                                mediante estrategias comerciales. El webinar
                                será impartido por Gabriela Rivera, ella es
                                Gerente Comercial de Hospital Cumbres y cuenta
                                con basta experiencia en temas de
                                comercialización y mercadotecnia, mostrando
                                excelentes resultados.
                            </p>
                        </div>
                    </Space>
                </div>
            </div>
            <Space
                style={{
                    flex: 1,
                }}
            >
                <div style={{ zIndex: 2, position: "relative" }}>
                    <Image preview={false} src={NewsBackground}></Image>
                </div>
            </Space>
        </div>
    );
}
