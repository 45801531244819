import useHeaders from "../utils/useHeaders";
import { URI_API } from "../utils/api";

const getAllHospitalAreas = async () => {
    return await fetch(`${URI_API}/hospital_area/all`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((res) => res.json());
};

export default {
    getAllHospitalAreas,
};
