import BackgroundTexture from "../assets/home_view/background_texture.jpg";
import IconoCasosDeExito from "../assets/home_view/icono_casosdeexito.svg";
import BackgroundShapes from "../assets/home_view/background_shapes.svg";
import IconoConoce from "../assets/home_view/icono_conoceotrasideas.svg";
import CardBackground1 from "../assets/home_view/card_background1.jpg";
import CardBackgorund2 from "../assets/home_view/card_background2.jpg";
import CardBackground3 from "../assets/home_view/card_background3.jpg";
import Cardbackground4 from "../assets/home_view/card_background4.jpg";
import IconoTusIdeas from "../assets/home_view/icono_tus ideas.svg";
import { ILoggedUser, deleteLoggedUser } from "../store/actions";
import IdeasLogo from "../assets/ideas_logo_traditional.png";
import IconoNews from "../assets/home_view/icono_news.svg";
import LogoutIcon from "../assets/home_view/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Image, Space } from "antd";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Dispatch } from "redux";

export default function HomeView() {
    const dispatch: Dispatch<any> = useDispatch();

    const navigator = useNavigate();

    const loggedUser: ILoggedUser = useSelector(
        (state: any) => state.loggedUser.loggedUser
    );

    const { instance } = useMsal();

    let cardItems = [
        {
            backgroundImage: CardBackground1,
            url: "/your_ideas",
            cardIcon: IconoTusIdeas,
            title: "Tus ideas",
            description:
                "Las soluciones comienzan con ideas. ¡Comparte una idea con nosotros!",
        },
        {
            backgroundImage: CardBackgorund2,
            url: "/success_cases",
            cardIcon: IconoCasosDeExito,
            title: "Casos de éxito",
            description: "Conoce nuestras ideas CHRISTUS.",
        },
        {
            backgroundImage: CardBackground3,
            url: "/meet_ideas",
            cardIcon: IconoConoce,
            title: "Conoce otras ideas",
            description: "Conoce las ideas de otros colaboradores.",
        },
        {
            backgroundImage: Cardbackground4,
            url: "/news",
            cardIcon: IconoNews,
            title: "News",
            description:
                "Echa un vistazo a los casos de éxito, comunicación y otras noticias.",
        },
    ];

    const logout = (): void => {
        instance.logoutRedirect();
        dispatch(deleteLoggedUser);
    };

    if(loggedUser != undefined)
    return (
        <div>
            <img
                src={BackgroundTexture}
                alt=""
                width={"100%"}
                style={{ zIndex: -1, position: "absolute", opacity: 0.3 }}
            />
            <img
                src={BackgroundShapes}
                alt=""
                width={"100%"}
                style={{ zIndex: -1, position: "absolute" }}
            />
            {/* HEADER */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "3%",
                    alignItems: "center",
                }}
            >
                <Image src={IdeasLogo} width={"10%"} preview={false}></Image>
                <Space
                    direction="horizontal"
                    style={{
                        background: "rgb(255,255,255, 0.5)",
                        borderRadius: "8px",
                        alignItems: "center",
                        paddingLeft: "1%",
                        paddingRight: "1%",
                        height: "55px",
                        justifyContent: "space-between",
                    }}
                >
                    {/* avatar */}
                    <Avatar
                        style={{
                            backgroundColor: "#8A1B61",
                            verticalAlign: "middle",
                        }}
                        size={"large"}
                    >
                        {loggedUser?.initials}
                    </Avatar>
                    <Space direction="vertical">
                        <p
                            style={{
                                fontFamily: "quicksand-700",
                                fontSize: "14px",
                                color: "#8A1B61",
                            }}
                        >
                            {loggedUser?.fullName}
                            <br />
                            <span
                                style={{
                                    fontFamily: "quicksand-500",
                                    color: "black",
                                }}
                            >
                                {loggedUser?.roleName}
                            </span>
                        </p>
                    </Space>
                    <div style={{ cursor: "pointer" }} onClick={logout}>
                        <Image
                            src={LogoutIcon}
                            width={"120%"}
                            preview={false}
                        ></Image>
                    </div>
                </Space>
            </div>
            {/* CARDS */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "15%",
                    marginTop: "3%",
                    marginRight: "15%",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                {cardItems.map((item) => (
                    <div
                        onClick={() => navigator(item.url)}
                        style={{
                            width: "24%",
                            maxWidth: "24%",
                            maxHeight: "30%",
                            backgroundImage: `url(${item.backgroundImage})`,
                            backgroundSize: "112%",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "24px",
                        }}
                    >
                        <div
                            style={{
                                margin: "10%",
                            }}
                        >
                            <img src={item.cardIcon} alt="" width={"40%"} />
                            <h2
                                style={{
                                    fontFamily: "inter-bold",
                                    fontSize: 28,
                                    color: "white",
                                }}
                            >
                                {item.title}
                            </h2>
                            <p
                                style={{
                                    fontFamily: "quicksand-600",
                                    fontSize: 13,
                                    color: "white",
                                }}
                            >
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ); else
        return (
        <div>
            Cargando
        </div>
        );
}
