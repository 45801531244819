import { useDroppable } from "@dnd-kit/core";
import KanbanCard from "./kanbanCard";

interface KanbanLaneProps {
    title: string;
    items: any[];
    headerColor: string;
}

export default function KanbanLane({ title, items, headerColor }: KanbanLaneProps) {
    const { setNodeRef } = useDroppable({
        id: title,
    });
    return (
        <div style={{
            flex: 3,
            padding: 5,
            flexDirection: 'column',
            minHeight: '10rem',
            maxHeight: '80vh',
            display: 'flex'
        }}>
            <div style={{
                padding: '5%',
                backgroundColor: headerColor,
                marginBottom: '5%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'quicksand-600',
                color: (title===("IDEA") || title===("EN REVISIÓN") || title===("BACKLOG"))? 'black': 'white',
                borderRadius: '8px',
                position: 'sticky',
                top: 5,
            }}>{title}</div>
            <div ref={setNodeRef} style={{
                backgroundColor: "gray.200",
                borderRadius: '8px',
                flex: 1,
                padding: 2,
                flexDirection: 'column'
            }}>
                {items.map(({ title: cardTitle, created_at, authors, idea_id }, key) => (
                    <KanbanCard authors={authors} ideaDate={created_at} idea_id={idea_id} title={cardTitle} key={key} index={key} parent={title} />
                ))}
            </div>
        </div>
    );
}