import { combineReducers } from "redux";
import { ILoggedUser, SET_LOGGED_USER, DELETE_LOGGED_USER } from "./actions";

const initialState = {};

const loggedUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_LOGGED_USER:
            return {
                ...state,
                loggedUser: action.payload,
            };

        case DELETE_LOGGED_USER:
            return {
                ...state,
                loggedUser: {},
            };

        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    loggedUser: loggedUserReducer,
});
