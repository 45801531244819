import { URI_API, URI } from "../utils/api";
import useHeaders from "../utils/useHeaders";

const getAllUsers = async () => {
    return await fetch(`${URI_API}/user/all`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((res) => res.json());
};

const getAllAuthors = async () => {
    return await fetch(`${URI_API}/user/authors`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    })
        .then((res) => res.json())
        .then((res) => eval(res));
};

const loginUser = async (body: any) => {
    return await fetch(`${URI}/token`, {
        method: "POST",
        body: body,
    }).then((res) => res.json());
};

const getUserLogged = async () => {
    return await fetch(`${URI_API}/user/me`, {
        method: "GET",
        headers: useHeaders.getHeaders(),
    }).then((res) => res.json());
};

export default {
    getAllUsers,
    loginUser,
    getUserLogged,
    getAllAuthors,
};
