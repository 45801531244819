// KanbanCard.tsx
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Avatar, Tooltip } from "antd";

export default function KanbanCard({
    title,
    index,
    parent,
    ideaDate,
    authors,
    idea_id
}: {
    title: string;
    ideaDate: string;
    index: number;
    parent: string;
    authors: any[];
    idea_id: number
}) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: title,
        data: {
            title,
            index,
            parent,
            created_at: ideaDate,
            authors,
            idea_id
        },
    });
    const style = {
        transform: CSS.Translate.toString(transform),
    };
    const statusColor = () => {
        switch (parent) {
            case "IDEA":
                return "#ADDC91"
            case "PLANEACIÓN":
                return "#468F2A"
            case "EN REVISIÓN":
                return "#97D700"
            case "FINALIZADO":
                return "#671E75"
            case "IMPLEMENTACIÓN":
                return "#8A1B61"
            case "BACKLOG":
                return "#BEBEBE"
        }
    }
    // const convertDate = (value: string) => {
    //     let date = new Date(value)
    //     return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    // }
    return (
        <div style={{
            padding: '8%',
            backgroundColor: '#FFFFFF',
            margin: 2,
            borderRadius: 8,
            border: "1px solid #DADADA",
            transform: style.transform,
            height: '12vh',
            // minHeight: '18%',
            marginBottom: '10%',
            borderRightColor: statusColor(),
            borderRightWidth: '13px',
            maxHeight: '12vh',
        }} {...listeners}
            {...attributes}
            ref={setNodeRef}>
            <div style={{
                fontFamily: 'quicksand-600',
                fontSize: '20px',
                overflow: 'hidden',
                display: '-webkit-box',
                lineClamp: 3,
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical'
            }}>
                {title}
            </div>
            {/* <div style={{
                fontFamily: 'quicksand-400',
                fontSize: 16,
                color: '#63666A',
                fontWeight: 'bold'
            }}>{convertDate(ideaDate)}</div>
            <div style={{
                marginTop: '20%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 0
            }}>
                <Avatar.Group maxCount={2}>
                    {authors.map(author => (
                        <Tooltip title={author['first_name'] + ' ' + author['last_name']} placement="top">
                            <Avatar style={{
                                backgroundColor: '#63666A',
                                color: 'white'
                            }}>{author['first_name'][0].toUpperCase()}</Avatar>
                        </Tooltip>
                    ))}
                </Avatar.Group>
            </div> */}
        </div>
    );
};