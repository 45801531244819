import { useNavigate, useLocation } from "react-router-dom";
import ideasServices from "../services/ideasServices";
import { useEffect, useState } from "react";
import { Button, notification } from "antd";
import "./idea_information.css";

export default function IdeaInformation() {
    const location = useLocation();
    const navigator = useNavigate();
    const [idea, setIdea] = useState<any>({});
    const [authors, setAuthors] = useState([]);

    const upvote = (ideaId: number) => {
        ideasServices.upVote(ideaId).then(() =>
            notification.success({
                message: "¡Muchas gracias por tu voto!",
            })
        );
    };

    useEffect(() => {
        let ideaId = location.state.idea_id;

        ideasServices.getIdea(ideaId).then((res) => {
            setIdea(res);
            setAuthors(res.authors);
        });
    }, []);

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <img
                src={require("../assets/left_background_idea.jpg")}
                style={{
                    position: "absolute",
                    height: "100%",
                    opacity: 0.3,

                    zIndex: 1,
                }}
            ></img>
            <div
                style={{
                    flex: 4,
                    marginBlock: "60px",
                    marginInline: "80px",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 2,
                }}
            >
                <Button
                    style={{
                        maxWidth: "100px",
                    }}
                    onClick={() => navigator(-1)}
                >
                    Regresar
                </Button>
                <div id="idea-container">
                    <h1 id="idea-title">{idea["title"]}</h1>
                    <div id="idea-information">{idea.description}</div>
                    <div>
                        <h3 id="idea-subtitle">Autor(es):</h3>
                        {authors.map((author) => (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div className="idea-author-name">
                                    {author["first_name"]} {author["last_name"]}
                                </div>
                                <span> &nbsp;</span>
                                <div className="idea-author-job">
                                    ({author["role_name"]})
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h3 id="idea-subtitle">Unidad:</h3>
                        <div id="idea-unit-information">
                            {idea["hospital_name"]} - {idea["area_name"]}
                        </div>
                    </div>
                </div>
                <div id="idea-footer">
                    <img
                        src={require("../assets/ideas_logo_traditional.png")}
                        height={100}
                    ></img>
                    <div id="like-container">
                        <div id="text">
                            <h5>¿Te agrada esta idea?</h5>
                            <span>¡Dale "Me gusta"!</span>
                        </div>
                        <div
                            onClick={() => upvote(idea["idea_id"])}
                            id="like-button"
                        >
                            <img
                                src={require("../assets/favorite_idea_icon.png")}
                                height={100}
                            ></img>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    flex: 3,
                    zIndex: 2,
                    overflow: "hidden",
                }}
            >
                <img
                    src={require("../assets/right_background_idea.png")}
                    style={{
                        height: "100%",
                    }}
                ></img>
            </div>
        </div>
    );
}
